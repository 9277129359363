import { Block } from "baseui/block";
import DataTable, { FilterTypes } from "../../components/data-table/data-table";
import { useGet } from "@/hooks/apiHooks";
import { Tag, TagKind } from "baseui/tag";
import {PLACEMENT, StatefulTooltip} from "baseui/tooltip";
import { CandidateData, Job } from "@/network/definitions/job";

const CandidateList = () => {
    const {
        isLoading,
        data: {
            data: jobs = [],
        } = {},
    } = useGet<Job>('agency-api/jobs');

    const getApplicationReviewTag = (status: string) => {
        let tagKind: TagKind;
        let statusText: string;
        if (status?.toLowerCase() === 'approved') {
            tagKind = TagKind.positive;
            statusText = 'Approved';
        } else if (status?.toLowerCase() === 'rejected') {
            tagKind = TagKind.negative;
            statusText = 'Rejected';
        } else {
            tagKind = TagKind.warning
            statusText = 'Pending';
        }
        return <Tag
            closeable={false}
            kind={tagKind}
            overrides={{
                Root: {
                    style: {
                        height: '24px',
                        lineHeight: '20px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                    },
                },
            }}
        >{statusText}</Tag>
    }
    const getReviewerComments = (reviewerComments: string) => {
        const comments = reviewerComments?.trim()
        if (comments) {
            return (
                <StatefulTooltip
                    content={() => <Block style={{maxWidth: 300}}>{comments}</Block>}
                    placement={PLACEMENT.top}
                    showArrow
                >
                    <Block style={{maxWidth: 400, textOverflow: 'ellipsis', overflow: 'hidden'}}>{comments}</Block>
                </StatefulTooltip>
            );
        }
        return ''
    }
    const columnsConfig = [
        { field: 'developer_id', headerName: 'Candidate id', sortable: false, },
        { field: 'email', headerName: 'Email', sortable: true, filterType: FilterTypes.Text, pinned: true },
        { field: 'name', headerName: 'Name', filterType: FilterTypes.Text },
        { field: 'resume', headerName: 'Resume link', render: (row) => <a href={row.resume} target="_blank" rel="noopener noreferrer">Link</a> },
        {
            field: 'job_title',
            headerName: 'Job role',
            filterCustomName: 'job_id',
            filterType: FilterTypes.Select,
            filterValues: jobs.map((job: Job) => ({
                label: `${job.id}-${job.role}`,
                value: job.id
            })),
            render: (row: CandidateData) => `${row.job_id} - ${row.job_title}`
        },
        {
            field: 'expected_rate',
            headerName: 'Rate',
        },
        {
            field: 'location',
            headerName: 'Location',
        },
        {
            field: 'notice_period',
            headerName: 'Availability',
            render: (row: CandidateData) => `${row.notice_period} weeks`
        },
        {
            field: 'note',
            headerName: 'Agency notes',
        },
        {
            field: 'application_review_status',
            headerName: 'Review status',
            render: (row: CandidateData) => getApplicationReviewTag(row.application_review_status)
        },
        {
            field: 'rejection_reason',
            headerName: 'Rejection reason',
            render: (row: CandidateData) => getReviewerComments(row.reviewer_comments),
        },
        {
            field: 'mti_status',
            headerName: 'First interview round status',
        },
        {
            field: 'onboarding_status',
            headerName: 'Onboarding status',
        },
        {
            field: 'trial_start_date',
            headerName: 'Trial start date',
        },
        {
            field: 'final_developer_rate',
            headerName: 'Final developer rate',
        },
        { field: 'uploaded_date', headerName: 'Uploaded On' },
    ];

    return (
        <Block>
            <DataTable<CandidateData>
                apiEndpoint="agency-api/candidates"
                initialColumnsConfig={columnsConfig}
                loading={isLoading}
            />
        </Block>
    )
}

export default CandidateList;
